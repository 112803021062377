<template>
	<b-modal id="add-connection" size="xl" title="Add Connection" ref="modal" ok-title="Add" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<!-- Select Connection -->
				<b-row class="my-2">
					<b-col sm="8">
						<b>SELECT CONNECTION</b>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Source">
							<v-select name="Source" class="style-chooser" label="text" placeholder=" - Please select - "
								:options="fromCompanyOptions" :reduce="(company) => company.value"
								v-model="selFromCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Source')" class="help-block">{{
								errors.first('Source')
							}}</span>
						</b-form-group>

						<b-card :title="selFromCompanyName" v-show="selFromCompany && selFromCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selFromCompany ? selFromCompany.type : '-' }}
								<br />
								<b>Industry:</b>
								{{ getCompanyValue(selFromCompany,'industry') }}
								<br />
								<b>Address:</b>
								{{ getCompanyValue(selFromCompany,'address') }}
								<br />

								<span v-if="selFromCompany && selFromCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Destination">
							<v-select name="Destination" class="style-chooser" label="text" placeholder=" - Please select - "
								:options="toCompanyOptions" :reduce="(company) => company.value" v-model="selToCompany"
								v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Destination')" class="help-block">{{
								errors.first('Destination')
							}}</span>
						</b-form-group>

						<b-card :title="selToCompanyName" v-show="selToCompany && selToCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selToCompany ? selToCompany.type : '-' }}
								<br />
								<b>Industry:</b>
								{{ getCompanyValue(selToCompany,'industry') }}
								<br />
								<b>Address:</b>
								{{ getCompanyValue(selToCompany,'address') }}
								<br />
								<span v-if="selToCompany && selToCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
				</b-row>

				<!-- Select Locations -->
				<b-row class="mt-4">
					<b-col sm="6" md="6">
						<span class="details-view-title">SELECT LOCATIONS</span>
						<div class="details-view-subtitle">List of destination company storage locations</div>
					</b-col>
					<b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>
				<b-table show-empty striped hover :items="storageLocationItems" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>	
					<template v-slot:cell(selection)="row">
						<b-form-checkbox :id="row.item.id"
							v-model="storageLocationsObj[row.item.id]['isIncluded']" value="true"
							unchecked-value="false" />
					</template>
					<template v-slot:cell(geoaddress)="row">
						<span class="numFont">
							({{ row.item.geoaddress.latitude }}, {{ row.item.geoaddress.longitude }})
						</span>
					</template>
				</b-table>
				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>

				<!-- Other Details -->
				<b-row class="mt-4 mb-2">
					<b-col sm="8">
						<b>OTHER DETAILS</b>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Connection Types">
							<b-form-text class="connection-types">Select at least 1 connection type</b-form-text>
							<b-form-checkbox-group stacked id="connectionTypes" name="Connection Types" v-model="form.connections">
								<b-form-checkbox v-bind:key="conType.id" :value="conType.value"
									v-for="conType in connectionTypes">{{ conType.text }}
								</b-form-checkbox>
							</b-form-checkbox-group>
							<b-form-group v-show="showOthersField" label="If Others, please specify" label-for="others" class="mt-2" >
								<b-form-input name="Others" v-model="form.others" type="text" v-validate="{
									required: showOthersField,
								}" placeholder="Please specify"></b-form-input>
								<span v-show="errors.has('Others')" class="help-block">{{
									errors.first('Others')
								}}</span>
							</b-form-group>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Notes">
							<b-form-textarea id="notes" name="Notes" v-model="form.notes" placeholder="Other info here"
								:rows="2" :max-rows="6" maxlength="200" v-validate="{
									required: true,
									regex: remarksRegex
								}" />
							<span v-show="errors.has('Notes')" class="help-block">{{
								errors.first('Notes')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ConnectionUtil } from '@/utils/connectionUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// DAO
import companyDAO from '@/database/companies';
import connectionsDAO from '@/database/connections';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-connection',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allConnectedCompaniesObj: {
			type: Object,
			required: true
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		connectionTypes: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			fields: [
				{
					key: 'selection',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					sortable: true,
				},
				{
					key: 'address',
					sortable: false,
				},
				'geoaddress',
			],
			currentPage: 1,
            perPage: 5,
			totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

			form: { ...ConnectionUtil.getDefaultConnectionObj() },
			selFromCompany: { ...config.companyDefaultValue },
			selToCompany: { ...config.companyDefaultValue },
			fromCompanyOptions: {},
			toCompanyOptions: {},
			storageLocationItems: [],
			storageLocationsObj: {},
			showOthersField: false,

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompaniesObj: function () {
			// From Companies
			this.fromCompanyOptions = DropDownItemsUtil.retrieveActiveCompanies(this.allCompaniesObj);

			// To Companies
			this.toCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
		},
		'form.connections': function () {
			if (this.form.connections && this.form.connections.includes('Others')) {
				this.showOthersField = true;
			} else {
				this.showOthersField = false;
			}
		},
		selToCompany: function (newVal) {
			if (newVal && newVal.id) {
				this.retrieveStorageLocations(newVal);
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		selFromCompanyName() {
			return this.selFromCompany && this.selFromCompany.name;
		},
		selToCompanyName() {
			return this.selToCompany && this.selToCompany.name;
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			}

			let existsConnection = await this.hasExistingConnection();
			if (existsConnection) {
				this.$toaster.warning('There is already an existing connection between this two companies.');
				this.isLoading = false;
				return;
			}
			// Source and Destination must not be the same
			else if (this.selFromCompany.id === this.selToCompany.id) {
				this.$toaster.warning('Source and Destination Company must be different.');
				this.isLoading = false;
				return;
			}
			// At least 1 connection required
			else if (this.form.connections.length <= 0) {
				this.$toaster.warning('You should have at least 1 connection type between the selected companies.');
				this.isLoading = false;
				return;
			}
			// if Others is selected, the others field is required
			else if (
				this.form.connections.includes('Others') &&
				this.form.others.length <= 0
			) {
				this.$toaster.warning('Please fill-in the Others field to proceed.');
				this.isLoading = false;
				return;
			}
			// At least 1 storage location must be connected
			else if (!this.hasAtLeastOneStorageLocation()) {
				this.$toaster.warning('Please select at least 1 storage location to connect.');
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		hasAtLeastOneStorageLocation() {
			let hasStorageLocation = false;
			let locArr = Object.values(this.storageLocationsObj);
			for (const loc of locArr) {
				if (loc.isIncluded === 'true') {
					hasStorageLocation = true;
					break;
				}
			}
			return hasStorageLocation;
		},
		async hasExistingConnection() {
			let status = false;
			if (this.selFromCompany.id && this.selToCompany.id) {
				let connectionObj = await connectionsDAO.getConnection(
					this.selFromCompany.id,
					this.selToCompany.id
				);

				return !_.isEmpty(connectionObj);
			}
			return status;
		},

		getParam() {
			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);
			this.form.others = ValidationUtil.removeExcessWhiteSpace(this.form.others);

			// update selection
			this.updateFromCompany();
			this.updateToCompany();
			this.form.storageLocations = this.storageLocationsObj;

			return this.form;
		},
		updateFromCompany() {
			this.form.company = this.getCompany(this.selFromCompany.id);
			this.form.company.geoaddress = LocationUtil.getGeoaddress(this.form.company.geoaddress);
			this.form.companyId = this.selFromCompany.id;
		},
		updateToCompany() {
			this.form.connectedCompany = this.getCompany(this.selToCompany.id);
			this.form.connectedCompany.geoaddress = LocationUtil.getGeoaddress(this.form.connectedCompany.geoaddress);
			this.form.connectedCompanyId = this.selToCompany.id;
		},
		getCompany(id) {
			if (this.allCompaniesObj[id]) {
				return this.allCompaniesObj[id];
			} else if (this.allConnectedCompaniesObj[id]) {
				return this.allConnectedCompaniesObj[id];
			}
			return { ...config.companyDefaultValue };
		},
		
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				const result = await this.$store.dispatch('addConnection', this.getParam());

				if (result.isSuccess) {
					// Update company setup status
					await companyDAO.updateCompanySetupStatus({
						companyId: this.selFromCompany.id,
						data: {
							hasConnection: true,
						}
					});

					this.$toaster.success(`New Connection between ${this.selFromCompany.name} and ${this.selToCompany.name} was created successfully.`);
					EventBus.$emit('onCloseSaveConnection', result.data);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error creating connection between ${this.selFromCompany.name} and ${this.selToCompany.name}. Please try again.`);
				}
			} catch (error) {
				this.$toaster.error(`Error creating connection between ${this.selFromCompany.name} and ${this.selToCompany.name}. Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...ConnectionUtil.getDefaultConnectionObj() };

			this.selFromCompany = { ...config.companyDefaultValue };
			this.selToCompany = { ...config.companyDefaultValue };

			this.storageLocationItems = [];
			this.storageLocationsObj = {};

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},

		retrieveStorageLocations(selCompany) {
			// reset values
			this.storageLocationItems = [];
			this.storageLocationsObj = {};

			let storageLocations = _.filter(this.allStorageLocationsObj, o => {
				return o.companyId === selCompany.id;
			})
			_.forEach(storageLocations, (value) => {
				let loc = value;
				this.storageLocationsObj[loc.id] = {
					id: loc.id,
					name: loc.name,
					address: loc.address,
					geoaddress: LocationUtil.getGeoaddress(loc.geoaddress),
					areaRadius: loc.areaRadius ? loc.areaRadius : 100,
					isIncluded: 'false',
					isActive: loc.isActive,
				};

				this.storageLocationItems.push(this.storageLocationsObj[loc.id]);
			});

			this.totalRows = _.size(this.storageLocationItems);
		},
		getCompanyValue(companyObj, fieldName) {
			let companyId = companyObj ? companyObj.id : null;
			if (companyId && this.allCompaniesObj[companyId]) {
				return this.allCompaniesObj[companyId][fieldName];
			}
			return '';
		}
	},
};
</script>

<style scoped>
.connection-types {
	margin-top: -5px;
	margin-bottom: 10px;
}
</style>